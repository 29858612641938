import { APIFilter, API_FILTER_OPERATORS } from '@/utils/api'
import { toDate, endOfDay } from '@/utils/date'

export default {
  async selectFilterLookups (Vue) {
    const apiFilterEstadoActivo = new APIFilter()
    apiFilterEstadoActivo
      .setOperator(API_FILTER_OPERATORS.AND)
      .addGT('estado', 0)
    let apiCalls = []
    apiCalls.push({ name: 'selectTactuacion', method: 'tactuacion.select', params: { filter: apiFilterEstadoActivo } })
    apiCalls.push({ name: 'selectTsistema', method: 'tsistema.select', params: { filter: apiFilterEstadoActivo } })
    return await Vue.$api.batchCall(apiCalls)
  },
  async selectPresupuestocli (Vue, filter, search, sorter, page, estados, idcliente, idclientePotencial, mapBounds, pageSize) {
    const apiFilter = new APIFilter()
      .addGT('idest_presupuestocli', 0)
      .addIn('idest_presupuestocli', estados)
    if (idcliente)
      apiFilter.addExact('idcliente', idcliente)
    if (idclientePotencial)
      apiFilter.addExact('idcliente_potencial', idclientePotencial)
    if (search) {
      apiFilter.addNestedFilter(Vue.$online.presupuestocli.searchFilter(search))
    }
    if (filter.fdesde.value) {
      apiFilter.addGTE(filter.fdesde.field, toDate(filter.fdesde.value))
    }
    if (filter.fhasta.value) {
      apiFilter.addLTE(filter.fhasta.field, endOfDay(toDate(filter.fhasta.value)))
    }
    if (filter.tactuacion.value) {
      apiFilter.addExact(filter.tactuacion.field, filter.tactuacion.value)
    }
    if (filter.tsistema.value) {
      apiFilter.addExact(filter.tsistema.field, filter.tsistema.value)
    }
    if (filter.idcliente.value) {
      apiFilter.addExact(filter.idcliente.field, filter.idcliente.value)
    }
    if (filter.idclientePotencial.value) {
      apiFilter.addExact(filter.idclientePotencial.field, filter.idclientePotencial.value)
    }
    if (mapBounds) {
      apiFilter.addGT('latitud', mapBounds.swLat)
      apiFilter.addLT('latitud', mapBounds.neLat)
      apiFilter.addGT('longitud', mapBounds.swLng)
      apiFilter.addLT('longitud', mapBounds.neLng)
    }
    const resp = await Vue.$api.call('presupuestocli.select', {
      page,
      filter: apiFilter,
      sorter,
      page_size: pageSize,
    })
    return [resp.data.result.dataset, resp.data.result.metadata]
  },
  async selectPresupuestocliRows (Vue, pks, estados) {
    const apiFilter = new APIFilter()
    apiFilter
      .setOperator(API_FILTER_OPERATORS.AND)
      .addIn('idpresupuestocli', pks)
      .addIn('idest_presupuestocli', estados)
    const resp = await Vue.$api.call('presupuestocli.select', {
      filter: apiFilter,
    })
    return [resp.data.result.dataset, resp.data.result.metadata]
  },
}
